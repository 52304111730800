/*********************************************************************************************************************
 * @file Jobcard top panel component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/
import React, { PureComponent } from 'react';

import { TagType } from '@licoriceio/constants';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setMeta, setHideClientStatus } from '../../redux/actions/index.js';
import { TypeAheadControl } from '../../redux/reducers/typeAhead.js';
import { selectJobHasNotes, getJobCompany, selectJobHasAssets } from '../../redux/selectors/index.js';
import { topRow,
    estimatedTimeStyle,
    estimatedTimeInput,
    spacer,
    companyStatus,
    statusText,
    statusName,
    statusHidden,
    readOnlyClient,
    displayNone,
    displayFlex,
    boardTypeContainer
} from '../../scss/JobCardForm.module.scss';
import { __ } from '../../utils/i18n.jsx';
import { 
    UX_JOBCARD_CLIENT_DROPDOWN, UX_JOBCARD_TITLE, UX_JOBCARD_DESCRIPTION, UX_JOBCARD_ESTIMATED_TIME, UX_JOBCARD_CLIENT_STATUS, UX_JOBCARD_CLIENT_CUSTOM_STATUS
} from '../../ux-constants.js';
import { closeEitherCard } from '../calendar/shared.js';
import DynamicSelect from '../common/DynamicSelect.jsx';
import { 
    LicoSwitch, LicoTextArea, LicoTextField, LicoTimeTextField, PriorityButton, LicoFormItemWrapper, LicoFab, LicoIcon, H2, LicoSelect
} from '../common/index.js';
import TagChip from '../common/TagChip.jsx';
import TagSelect from '../common/TagSelect.jsx';

import { baseSlicePackage } from './reducer.js';

class JobCardForm extends PureComponent {

    render() {
        const {
            job,
            clientAutoFocus,
            companyName,
            companyStatusName,
            companyStatusDescription, 
            readOnlyCompany,
            customNote,
            isClientSelectDisabled,
            closeEitherCard,
            hideClientStatus,
            setHideClientStatus,
            notesFullView,
            completed,
            boardTypeMap,
            jobType,
            jobSubType,
            jobItem
        } = this.props;
        const {
            jobId,
            priority,
            estimatedTime,
            title,
            description,
            providerBoardId
        } = job;

        const slicePackage = { ...baseSlicePackage, id: jobId, keySaveAction: { Escape: closeEitherCard } };

        return <>

            <div className={topRow}>
                <div  className={notesFullView ? displayNone : displayFlex}>
                    <PriorityButton
                        labelClass= {notesFullView ? displayNone : ''}
                        name="priority"
                        value={priority}
                        slicePackage={slicePackage}
                        disabled={completed}
                    />
                    <LicoTimeTextField
                        label={notesFullView ? '' : __( "Total estimated time:" )}
                        initialValue={estimatedTime}
                        name="estimatedTime"
                        slicePackage={slicePackage}
                        className={estimatedTimeStyle}
                        inputClassName={estimatedTimeInput}
                        disabled={completed}
                        data-ux={UX_JOBCARD_ESTIMATED_TIME}

                    />
                </div>
                <span className={spacer} />
                <LicoSwitch
                    name="completedDate"
                    checked={completed}
                    label={__( "Resolved" )}
                    slicePackage={slicePackage}
                    valueTransform={value => value ? new Date() : null}
                />

            </div>

            <Grid container spacing={2} className = {notesFullView ? displayNone : ''}>
                <Grid item xs={4}>
                    { isClientSelectDisabled
                        ? <LicoTextField
                            label={__( "Client" )}
                            value={companyName}
                            disabled={true}
                            tooltip={ __( "The client cannot be changed after chat entries or assets are added, or the job is resolved." )}
                        />
                        : <LicoFormItemWrapper
                            label={__( "Client" )}
                        >
                            <DynamicSelect
                                dataName={UX_JOBCARD_CLIENT_DROPDOWN}
                                placeholder={__( "Find User or Client..." )}
                                name={TypeAheadControl.companyId}
                                initialText={companyName}
                                autoFocus={clientAutoFocus}
                                slicePackage={slicePackage}
                            />
                        </LicoFormItemWrapper>
                    }
                </Grid>

                <Grid item xs={8}>
                    <LicoTextField
                        name="title"
                        data-ux={UX_JOBCARD_TITLE}
                        label={__( "Title" )}
                        placeholder={__( "Type a short title" )}
                        value={title}
                        disabled={completed}
                        slicePackage={slicePackage}
                    />
                </Grid>
            </Grid>

            <div className={notesFullView ? displayNone : ''}>
                {companyStatusDescription && <div className={ `${companyStatus} ${hideClientStatus ? statusHidden : ''} ${readOnlyCompany ? readOnlyClient : ''}`}>
                    <div className={ statusText} data-ux={UX_JOBCARD_CLIENT_STATUS}>
                        <span className={statusName}>{companyStatusName}: </span>
                        {companyStatusDescription}
                        {customNote
                            ? <div data-ux={UX_JOBCARD_CLIENT_CUSTOM_STATUS}>
                                <span className={statusName}>{__( "Customer Note:" )} </span>
                                {customNote}
                            </div>
                            : ''}
                    </div>
                    <div >
                        <LicoFab
                            onClick={() => setHideClientStatus( true )}
                        >
                            <LicoIcon icon="close" />
                        </LicoFab>

                    </div>
                </div>}

                <div className={boardTypeContainer} >
                    <H2 gutterBottom={false}>{ __( "Description" )}</H2>
                    <span style={{ flexGrow: 1 }} />
                    { providerBoardId && boardTypeMap && <>
                        { jobType
                            ? jobSubType
                                ? jobItem
                                    ? <>
                                        <TagChip tagName={jobType} tagType={TagType.jobType} tagOwnerId={job.jobId} />
                                        <TagChip tagName={jobSubType} tagType={TagType.jobSubType} tagOwnerId={job.jobId} />
                                        <TagChip tagName={jobItem} tagType={TagType.jobItem} tagOwnerId={job.jobId} canDelete />
                                    </>
                                    : <>
                                        <TagChip tagName={jobType} tagType={TagType.jobType} tagOwnerId={job.jobId} />
                                        <TagChip tagName={jobSubType} tagType={TagType.jobSubType} tagOwnerId={job.jobId} canDelete />
                                        <TagSelect 
                                            name="item"
                                            tooltip={ __( "Item" )}
                                            tagType={TagType.jobItem}
                                            tagOwnerId={job.jobId}
                                            options={boardTypeMap[ providerBoardId ].typeMap[ jobType ].subTypeMap[ jobSubType ]}
                                        />

                                    </>
                                : <>
                                    <TagChip tagName={jobType} tagType={TagType.jobType} tagOwnerId={job.jobId} canDelete />
                                    <TagSelect 
                                        name="subtype"
                                        tooltip={ __( "SubType" )}
                                        tagType={TagType.jobSubType}
                                        tagOwnerId={job.jobId}
                                        options={boardTypeMap[ providerBoardId ].typeMap[ jobType ].subTypes}
                                    />
                                </>
                            : <TagSelect 
                                name="type"
                                tooltip={ __( "Type" )}
                                tagType={TagType.jobType}
                                tagOwnerId={job.jobId}
                                options={boardTypeMap[ providerBoardId ].types}
                            />
                        }
                    </>
                    }
                </div>

                <LicoTextArea
                    data-ux={UX_JOBCARD_DESCRIPTION}
                    placeholder={__( "Type a detailed description" )}
                    name="description"
                    value={description}
                    minRows={4}
                    maxRows={4}
                    slicePackage={slicePackage}
                    disabled={completed}
                />
            </div>
        </>;
    }
    static propTypes = {
        job:                        PropTypes.any.isRequired,
        clientAutoFocus:            PropTypes.bool.isRequired,
        isClientSelectDisabled:     PropTypes.bool.isRequired,
        companyName:                PropTypes.string,
        closeEitherCard:            PropTypes.func.isRequired,
        companyStatusName:          PropTypes.string, 
        companyStatusDescription:   PropTypes.string, 
        readOnlyCompany:            PropTypes.bool,
        customNote:                 PropTypes.string,
        hideClientStatus:           PropTypes.bool.isRequired,
        setHideClientStatus:        PropTypes.func.isRequired,
        notesFullView:              PropTypes.bool,
        completed:                  PropTypes.bool.isRequired,
        boardTypeMap:               PropTypes.object,
        jobType:                    PropTypes.string,
        jobSubType:                 PropTypes.string,
        jobItem:                    PropTypes.string
    };
}

const mapStateToProps = ( state, props ) => {

    const isClientSelectDisabled = props.completed || selectJobHasNotes( state ) || selectJobHasAssets( state );
    const { companyName } = getJobCompany( state, props.job.jobId );

    return {
        isClientSelectDisabled,
        companyName,
        hideClientStatus:           state.jobcard.hideClientStatus,
        notesFullView:              state.jobcard.notesFullView,
        boardTypeMap:               state.jobcard.boardTypeMap,
        jobType:                    state.jobcard.jobType,
        jobSubType:                 state.jobcard.jobSubType,
        jobItem:                    state.jobcard.jobItem
    };
};

const mapDispatchToProps = {
    setMeta,
    closeEitherCard,
    setHideClientStatus
};

export default connect( mapStateToProps, mapDispatchToProps )( JobCardForm );
export { JobCardForm };
