/*********************************************************************************************************************
 * @file Jobcard reducers
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 13-Jan-2021
 *********************************************************************************************************************/

import {
    SECOND,
    NoteType,
    AppointmentState,
    ADD,
    DELETE,
    UserRole,
    StatusNames,
    Coms,
    BillingTypeNames,
    coms,
    integrationNames,
    NoteFlags
} from '@licoriceio/constants';
import { has, snapDuration, stringToNumMinutes, isEmpty } from '@licoriceio/utils';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from "uuid";

import { GET, PATCH, POST, uri, CC_EMAIL_ID, userDialogMode, AssetDialogMode } from '../../constants.js';
import {
    setDraftNoteText, setJobNotes, setAddJobNote, setPendingNote, setPendingDisplayNote, setUndoNoteTimeout, clearPendingNote,
    noteDataDeleted, setTouchedJobCard, setErrorJobCard, setMessageText,
    setNoteTextAction, setCommentBillable, setShowEditTimer, setEditableTime, setCurrentJobCardId, setJobCardDetails,
    setJobCardAppointments, setJobCardSinglePerson, setJobCardNewAppointment,
    setJobCardUsers, setJobCardEngineers, setJobCardChecklist, setJobCardChecklistItemCreate, setJobCardChecklistItemDelete, setJobCardChecklistItemUpdate,
    adjustTimeLogTime, setFinishTimeLog, setLocalJobTime, setUpdateJobcardUser, setUpdateCompany, setAppointmentHistory,
    patchJobCardUsers, patchJobCardEngineers, setHideClientStatus, setUpdateJob, clearContactChange, setNotesFullView,
    setChatTabIndex, setCloseUserDialog, setUserDialog, setJobCardAssets, updateJobCardAssets, deleteJobCardAsset, setSelectedAsset, setAssetFilterString, setSelectedAssetMode,
    setBillingType, setTypeAheadAssets, setTypeNames, setSkipClientEmail, setPatchNoteList, setBoardTypes, 
    setJobTag, deleteJobTag
} from '../../redux/actions/index.js';
import { cacheType, requestCacheRecord } from '../../redux/reducers/cache.js';
import { setSomeTypeNames } from '../../redux/reducers/names.js';
import { TypeAheadControl } from '../../redux/reducers/typeAhead.js';
import { ezRedux, focusReducer, genericRequest, genericReducer, dispatchChangeAction, dispatchBlurThunk } from '../../redux/reducerUtil.js';
import { getJobFromState, selectNewJobDetails, selectCacheRecord } from '../../redux/selectors/index.js';
import { 
    billableEnabled, billableDisabled, notBillableEnabled, notBillableDisabled, afterHoursEnabled, afterHoursDisabled, gratisEnabled, gratisDisabled
} from '../../scss/WorkLog.module.scss';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { setCacheData, getCacheData } from '../../sockets/cache-data.js';
import { getAvatarFromUserOrCompany, convertToLicoSelectOptions } from '../../utils/common-types.js';
import { __ } from '../../utils/i18n.jsx';
import { saveToUserSession } from '../../utils/local-storage.js';
import { patchJobEngineers } from '../calendar/requests.js';
import { addTimeLogOnPegboard, addPausedTimeLogOnPegboard } from '../calendar/shared.js';
import { setUpdateAppointmentStaticsThunk } from '../calendar/sharedThunks.js';
import { addAppointmentRequest } from '../calendar/thunks.js';
import { finishCurrentTimeLog, getCurrentJobTimerState, saveTimerValue } from '../pegboard/reducer.js';
import { addNewTabWithClient } from "../searchPanel/reducer.js";

import { getJobCardUsers, getJobCardEngineers, getJobCardAssets, getJobNotes } from './requests.js';

/**
 * @typedef {object}    JobCardState
 * @property {string}   currentJobCardId
 * @property {any}      appointments
 * @property {array}    _sortedAppointments
 * @property {array}    engineers
 * @property {array}    _engineersOptions
 * @property {array}    users
 * @property {array}    _usersOptions
 * @property {any}      checklist
 * @property {any}      notes
 * @property {string}   error
 * @property {object}   fieldTouched
 * @property {object}   fieldError
 * @property {string}   messageText
 * @property {boolean}  messageIsEmpty
 * @property {boolean}  draftMessageLoading
 * @property {boolean}  billable
 * @property {string}   billingTypeId
 * @property {boolean}  showEditTimer
 * @property {any}      undoNoteTimeout
 * @property {object}   pendingNote
 * @property {object}   pendingDisplayNote
 * @property {string}   editableTime
 * @property {boolean}  validEditTime
 * @property {boolean}  hideClientStatus
 * @property {boolean}  notesFullView
 * @property {number}   chatTabIndex
 * @property {object}   assets
 * @property {string}   defaultStatus
 * @property {string}   completedStatus
 * @property {boolean}  skipClientEmail
 * @property {object}   boardTypeMap
 * @property {string}   jobType
 * @property {string}   jobSubType
 * @property {string}   jobItem
 */

/**
  * @type {JobCardState}
  */
const initialState = Object.freeze({
    currentJobCardId:       null,
    appointments:           [],
    appointmentHistory:     [],
    _sortedAppointments:    [],
    engineers:              null,
    _engineersOptions:      null,
    users:                  null,
    _usersOptions:          null,
    checklist:              null,
    notes:                  [],
    error:                  '',
    fieldTouched:           {},
    fieldError:             {},
    messageText:            '',
    messageIsEmpty:         true,
    draftMessageLoading:    false,
    billable:               false,
    billingTypeId:          '',
    editableTime:           '',
    showEditTimer:          false,
    validEditTime:          true,
    statusHidden:           false,
    hideClientStatus:       false,
    notesFullView:          false,
    deferredContactChange:  {},
    chatTabIndex:           0,
    assetsMap:              {},
    selectedAsset:          {},
    skipClientEmail:        false,
    boardTypeMap:           null,
    jobType:                null,
    jobSubType:             null,
    jobItem:                null
});

// this data is used by the note entry field and the worklist item so it's defined here for shared use.
const billingTypeItemData = {
    [ BillingTypeNames.REGULAR ]: {
        enabledClass:       billableEnabled,
        disabledClass:      billableDisabled,
        icon:               "credit-card",
        iconClass:          "creditCard",
        billable:           true,
        iconSlashed:        false,
        tooltip:            __( "Billable: Hourly rate during business hours" ),
        name:               __( "Billable" )
    },
    [ BillingTypeNames.AFTER_HOURS ]: {
        enabledClass:       afterHoursEnabled,
        disabledClass:      afterHoursDisabled,
        icon:               "moon-stars",
        iconClass:          "moonstars",
        billable:           true,
        iconSlashed:        false,
        tooltip:            __( "After-hours: Hourly rate outside business hours" ),
        name:               __( "After Hours" )
    },
    [ BillingTypeNames.NOT_BILLABLE ]: {
        enabledClass:       notBillableEnabled,
        disabledClass:      notBillableDisabled,
        icon:               "credit-card",
        iconClass:          "noCreditCard",
        billable:           false,
        iconSlashed:        true,
        tooltip:            __( "Not-billable: There is a reason this is not chargeable" ),
        name:               __( "Not Billable" )
    },
    [ BillingTypeNames.GRATIS ]: {
        enabledClass:       gratisEnabled,
        disabledClass:      gratisDisabled,
        icon:               "gift",
        iconClass:          "gift",
        billable:           false,
        iconSlashed:        false,
        tooltip:            __( "Gratis: Normally chargeable, but we're gifting it" ),
        name:               __( "Gratis" )
    },
    [ BillingTypeNames.UNMAPPED ]: {
        enabledClass:       gratisEnabled,
        disabledClass:      gratisDisabled,
        icon:               "question",
        iconClass:          "gift",
        billable:           false,
        iconSlashed:        true,
        tooltip:            __( "Unmapped" ),
        name:               __( "Unmapped" )
    }
};


/** services */
const patchUsersService = abstractedCreateAuthRequest( PATCH, uri.JOB_USERS, a => a );

const _asyncPostChecklist = abstractedCreateAuthRequest( POST, uri.JOB_CHECKLIST, a => a );
const _asyncPatchChecklist = abstractedCreateAuthRequest( PATCH, uri.SINGLE_CHECKLIST, a => a );
const _asyncDeleteChecklist = abstractedCreateAuthRequest( DELETE, uri.SINGLE_CHECKLIST, a => a );

const _asyncPostNote = abstractedCreateAuthRequest( POST, uri.JOB_NOTES, a => a );

const _asyncGetSingleUser = abstractedCreateAuthRequest( GET, uri.SINGLE_USER, a => a );
const _asyncPostAssets = abstractedCreateAuthRequest( POST, uri.ASSET_TO_JOB );
const _asyncDeleteAsset = abstractedCreateAuthRequest( DELETE, uri.DELETE_ASSET );


/** requests */

// submit a new engineer/user or remove an existing one; return payload is not used
const patchJobUsers = payload => genericRequest( payload.data, patchUsersService, undefined, [ payload.id ]);
const addJobCardChecklistItem = payload => genericRequest( payload.task, _asyncPostChecklist, setJobCardChecklistItemCreate, [ payload.jobId ]);
const patchJobCardChecklistItem = payload => genericRequest( payload.task, _asyncPatchChecklist, undefined, [ payload.checklistId ]);
const deleteJobCardChecklistItem = checklistId => genericRequest({}, _asyncDeleteChecklist, [ [ setJobCardChecklistItemDelete, { checklistId } ] ], [ checklistId ]);
const addJobCardAsset = payload => genericRequest({ assetId: payload.assetId }, _asyncPostAssets, [ updateJobCardAssets ], [ payload.jobId ]);
const deleteJobCardAssetReq = payload => genericRequest({ assetId: payload.assetId }, _asyncDeleteAsset, [ deleteJobCardAsset ], [ payload.jobId ]);
const addJobNote = payload => genericRequest( 
    { note: payload.note, billingTypeId: payload.billingTypeId },
    _asyncPostNote, 
    [ [ setAddJobNote, { user: payload.user, billingTypeId: payload.billingTypeId } ] ],
    [ payload.note.jobId ]
);

const getSingleContact = payload => genericRequest({}, _asyncGetSingleUser, setJobCardSinglePerson, [ payload.id ]);

const patchUserRequest = payload => genericRequest( payload, abstractedCreateAuthRequest( PATCH, uri.SINGLE_USER ), setUpdateJobcardUser, [ payload.userId ]);
const createUserRequest = payload => genericRequest( payload.data, abstractedCreateAuthRequest( POST, uri.CLIENT_USER_ADD ), addUserToJob, [ payload.companyId ]);

/** thunk actions */

// if the job is open, refresh the people
const getJobCardPeopleIfOpen = () => async ( dispatch, getState ) => {
    const { jobcard: { currentJobCardId }, job: { jobMap } } = getState();
    if ( currentJobCardId && !jobMap[ currentJobCardId ]?.newItemFlag ) {
        dispatch( getJobCardUsers( currentJobCardId ) );
        dispatch( getJobCardEngineers( currentJobCardId ) );
    }
};

const setNoteText = payload => async ( dispatch ) => {

    dispatch( setNoteTextAction( payload ) );

    // starting a new note commits any pending note, so check with every change. 
    // We have to check the new value isn't still the editor's blank value, as the
    // new editor sends onChange events fairly freely, eg on navigation.
    if ( !payload.isEmpty )
        dispatch( sendPendingNote() );

};

const setJobCardPatchUser = payload => async ( dispatch  ) => {
    if ( payload.data.action === ADD && payload.data.people[ 0 ] === CC_EMAIL_ID )
    {

        // set up the contact info using the final typeahead filter value (which we know is a valid email)
        // as the email address
        const email = coms.create.email( payload.label.split( ' ' )[ 1 ]).work.default;
        const contactInfo = [ email ];

        // set the new user details (but don't show the form)
        dispatch( setUserDialog({
            mode:           userDialogMode.ADD,
            name:           '',
            title:          '',
            contactInfo
        }) );

        // save the new user and then chain on to adding the new user to the job
        dispatch( saveUserChanges() );
    }
    else {
        dispatch( patchJobCardUsers( payload ) );
        dispatch( patchJobUsers( payload ) );
    }
};

const setJobCardPatchEngineer = payload => async ( dispatch  ) => {

    // when we change owners, we're doing several changes in a row and refreshing in the middle
    // of them causes a crash (and is inefficient)
    if ( !payload.noRefresh )
        dispatch( patchJobCardEngineers( payload ) );
    dispatch( patchJobEngineers( payload ) );
};

// update (which currently only handles toggling the status) was looking slow so now
// does frontend immediately.
const updateJobCardChecklistItem = payload => async ( dispatch ) => {
    dispatch( setJobCardChecklistItemUpdate( payload ) );
    dispatch( patchJobCardChecklistItem( payload ) );
};

// dispatched when a field value changes
const localJobCardChange = payload => async ( dispatch, getState ) => {
    const state = getState();

    const job = getJobFromState( state, payload.id );

    // any change to the client id of an existing job should be reflected immediately in the user list,
    // both frontend and db.
    if ( 'companyId' in payload.updates ) {

        const { id, company } = payload.updates.companyId;

        // ensure company name is in map
        if ( payload.updates.companyId ) {
            dispatch( setUpdateCompany( company ) );
            dispatch( requestCacheRecord({ type: cacheType.COMPANY, id }) );
        }

        dispatch( getJobCardAssets( job?.jobId ) );

        // any change to the company resets the hiding of company status
        dispatch( setHideClientStatus( false ) );

        // clear the asset typeahead list
        dispatch( setTypeAheadAssets({ name: TypeAheadControl.assets, payload: [] }) );

        // we may have selected the company via a non-default contact; this contact should be used instead of the default
        const contactId = company?.userId || company?.contactId;

        // user adjustments
        if ( job.newItemFlag ) {
            // for new jobs we need to add the filter for user/company to the search panel
            dispatch( addNewTabWithClient( company ) );
            // For new jobs we just need frontend adjustment since the correct user is created automatically for new jobs.
            if ( company ) {
                dispatch( getSingleContact({ id: contactId }) );

                // record whatever contact was selected to preserve choice of non-default user.
                dispatch( setUpdateJob({ jobId: job.jobId, contactId }) );
            }
            else
                // we've changed the client id and now it's blank; we may need to clear the default
                // user seeded by a previous value for client id
                dispatch( setJobCardUsers([]) );
        }
        else {

            // if client specified (might have been cleared), we need to add the contact (default or selected) for the new client.
            if ( contactId ) {

                // We haven't committed the change yet (this is just the selection of a new client) and we can't change the contact
                // on the backend until the client change is done.
                // We update the UI; this will also record the contactId for saving once the client change is saved
                dispatch( getSingleContact({ id: contactId }) );
            }
        }
    }

};

// dispatched after the record is saved and merged
const localJobCardSave = ({ item, newItemFlag }) => async ( dispatch, getState ) => {
    const state = getState();

    // do the first-time operations
    if ( newItemFlag ) {
        const newJobDetails = selectNewJobDetails( state );
        if ( newJobDetails.jobAddedFromPegboard ) {

            // the company should be in the cache
            const companyMeta = selectCacheRecord( state, 'company', item.companyId );

            // add from pb? add to pb & maybe start timer, as long as it's not a read-only company
            if ( companyMeta?.readOnly ) 
                dispatch( addPausedTimeLogOnPegboard( item.jobId ) );
            else
                dispatch( addTimeLogOnPegboard( item.jobId ) );
        }
        else {

            // we've already created the appt in the calendar
            const { jobcard: { newAppointment }, user: { userId } } = state;
            setTimeout( () => dispatch( addAppointmentRequest( newAppointment ) ), 1000 );

            if ( userId !== newAppointment.userId )
                dispatch( patchJobEngineers({ id: item.jobId, data: { action: ADD, people: [ newAppointment.userId ] } }) );
        }
    }
    else {
        const { jobcard: { deferredContactChange }, auth } = state;

        dispatch( setUpdateAppointmentStaticsThunk() );

        // if we changed client, we should have set a new contactId as well, which we
        // couldn't update until the company change has completed.
        if ( has( deferredContactChange, item.jobId ) ) {

            const { newContactId, oldContactIds } = deferredContactChange[ item.jobId ];
            
            // have to add the new user before we can delete the old ones; a job must have at least one user
            patchUsersService({
                action:     ADD,
                people:     [ newContactId ]
            }, auth, [ item.jobId ]).then( 
                () => {
                    if ( oldContactIds.length > 0 ) 
                    { 
                        patchUsersService({
                            action:     DELETE,
                            people:     oldContactIds
                        }, auth, [ item.jobId ]).then( () => {
                            dispatch( clearContactChange( item.jobId ) );
                            dispatch( getJobCardUsers( item.jobId ) );
                        }
                        ); 
                    }
                    else 
                        dispatch( clearContactChange( item.jobId ) );
                } 
            );
        }
    }

};

const baseSlicePackage = {
    slice:              'job',
    localChangeThunk:   localJobCardChange,
    localSaveThunk:     localJobCardSave,
    qualifyingFields:   [ 'companyId', 'title', 'description' ]
};

const setJobOwner = payload => async () => {
    const slicePackage = { ...baseSlicePackage, id: payload.jobId };

    dispatchChangeAction( slicePackage, { updates: { [ payload.field ]: payload.userId } });
    dispatchBlurThunk( slicePackage, { field: payload.field });
};

const getAssetTypes = () => {
    return [];  // update with service call once available.
};


/*
Undo notes
Adding note actions:
    if pending object defined, it is sent to backend (note this may include changes from other jobs)
    add pending note and pending display note
    if time > 0, reset to 0 (locally)
    start 10s timeout
    timeout in state or pending object triggers display of undo button

Timeout actions
    pending note sent to backend (see completion of request)
    clear pending note, timeout

On completion of request
    clear pending display note; if we used pending note for display, it would disappear between send and completion of request

Undo button actions
    timeout cancelled
    restore saved time from pending note (locally)
    clear pending note, pending display note, timeout

*/

/**
 * Queues a note for sending after the undo timer expires
 * @param {object} payload - details of note to be added
 */
const queueNoteAction = payload => async ( dispatch, getState ) => {
    const state = getState();
    const { user, jobcard: { validEditTime, editableTime, pendingDisplayNote, billingTypeId, skipClientEmail }, organisation: { live: { minimumTimeEntry } } } = state;
    const { jobId, messageText, noteType } = payload;
    let timeLogId = payload.timeLogId;

    // make sure latest time edit has been saved (will change currentTimerSecs)
    if ( editableTime && validEditTime ) 
        dispatch( saveTimerValue( jobId, timeLogId, editableTime, billingTypeId ) );

    // if we didn't have a timeLogId, we must have edited and then gone straight to adding the note;
    // the timelog id should have been generated in saveTimerValue
    if ( !timeLogId ) {
        const { timeLogId: newTimeLogId } = getCurrentJobTimerState( getState(), jobId );
        timeLogId = payload.timeLogId = newTimeLogId;
    }

    const { currentTimerSecs } = getCurrentJobTimerState( getState(), jobId );
    payload.duration = snapDuration( currentTimerSecs * SECOND, minimumTimeEntry );
    if ( payload.duration === 0 )
        payload.billable = false;

    // set the note id so we can ignore the response
    payload.noteId = uuidv4();

    // billing type comes from current setting of dropdown
    payload.billingTypeId = billingTypeId;

    // if we had note text defined in 2 cards and then sent them in turn, we can get here
    // with a pending note, so send it
    dispatch( sendPendingNote() );

    // non-timed notes aren't billable
    if ( !payload.timeLogId )
        payload.billable = false;

    // clear the correct field based on type
    dispatch( setNoteTextAction({ noteType: payload.noteType, text: '', isEmpty: true }) );

    // reset the skip email flag if it was set
    if ( skipClientEmail ) 
    {
        payload.flags |= NoteFlags.SKIP_CLIENT_EMAIL;
        dispatch( setSkipClientEmail() );
    }

    // clear the timeout 

    // add note content to store (as pendingNote) and start undo timer
    dispatch( setPendingNote( payload ) );
    const timeout = setTimeout( () => dispatch( sendPendingNote() ), 10 * SECOND );
    dispatch( setUndoNoteTimeout( timeout ) );

    // immediate UI actions; clear the field, reset time to 0 and add a temporary note to the list

    // if timer is set, reset to 0 locally
    if ( currentTimerSecs > 0 ) 
        dispatch( adjustTimeLogTime({ timeLogId, pendingFinish: true }) );

    // there's only one temporary note; if another note is started, the pending note is committed immediately.
    // We need pendingNote *and* pendingDisplayNote because pendingNote lives until the note is posted
    // whereas pendingDisplayNote lives until the post returns; the gap is apparent.
    const { role, userId, avatar, name } = user;
    if ( pendingDisplayNote ) {
        // we've got a very quick 2nd note, and the first isn't back yet (this would clear PDN).
        // Add the current pending note to the real list so it doesn't disappear when we change PDN.
        dispatch( setAddJobNote({
            payload:        pendingDisplayNote,
            billable:       pendingDisplayNote.billable,
            billingTypeId:  pendingDisplayNote.billingTypeId,
            user
        }) );
    }

    dispatch( setPendingDisplayNote({
        jobId,
        noteId:         payload.noteId,
        timeLogId,
        content:        messageText,
        noteType,
        billable:       payload.billable,
        billingTypeId:  payload.billingTypeId,
        duration:       payload.duration,
        role,
        userId,
        avatar,
        name,

        // set the createdOn date for the display note so the date display works correctly.
        // This will not be the saved value (by a few seconds at most, hopefully) but I bet no-one notices.
        createdOn:      dayjs().toISOString()
    }) );

};

/**
 * Sends the pending note to the backend
 */
const sendPendingNote = () => async ( dispatch, getState ) => {
    const { user, jobcard: { pendingNote, undoNoteTimeout } } = getState();

    // this action is dispatched from a number of places without checking for a pending note;
    // if there isn't one, quit
    if ( !pendingNote )
        return;

    const { jobId, noteId, messageText, currentTimerSecs, noteType, billable, billingTypeId, timeLogId, duration, flags } = pendingNote;
    const note = {
        userId:     user.userId,
        noteId,
        jobId,
        noteType,
        content:    messageText,
        duration,
        timeLogId,
        flags
    };

    // the note is added with the timelog if there is one or posted separately if not
    if ( timeLogId ) {
        dispatch( finishCurrentTimeLog({ jobId, currentTimerSecs, billable, billingTypeId, note }) );
        dispatch( setLocalJobTime({ jobId, seconds: 0 }) );
    }
    else {
        dispatch( addJobNote({
            note,
            user,
            billingTypeId
        }) );
    }

    // clean up
    clearTimeout( undoNoteTimeout );
    dispatch( setPendingNote( null ) );
    dispatch( setUndoNoteTimeout( undefined ) );

};

const setUndoPendingNote = () => async ( dispatch, getState ) => {

    const { user: { userId }, jobcard: { currentJobCardId, pendingNote: { jobId, messageText, noteType, timeLogId } } } = getState();

    // restore text; we know a new note wasn't started (it would have committed the pending note)
    // so we either update the open card if it matches the job, or update the cache value.
    // Note that updating the open card also updates the cache.
    if ( jobId === currentJobCardId ) 
        dispatch( setNoteTextAction({ noteType, text: messageText }) );
    else
        setCacheData( `draftNote-${userId}-${jobId}-${noteType}`, { type: 'draftNote', noteType, text: messageText });

    if ( timeLogId )
        dispatch( adjustTimeLogTime({ timeLogId, pendingFinish: false }) );

    dispatch( clearPendingNote() );
};

const refreshCurrentJobNotes = payload => async ( dispatch, getState ) => {

    const { jobcard: { currentJobCardId } } = getState();

    // quit if the note's job isn't currently displayed
    if ( payload.jobId !== currentJobCardId )
        return;

    // commenting out this check because it blocks refresh when the user resolves/unresolves a job,
    // which causes a note to be added on the backend.
    // // ignore if this is the user who added the note and they did it in Licorice, not the provider.
    // // We can't do this via noteId because the real note might not be in the list yet.
    // if ( payload.userId === userId && !payload.providerNoteId )
    //     return;

    dispatch( getJobNotes( currentJobCardId ) );
};


const saveUserChanges = () => async ( dispatch, getState ) => {

    const state = getState();
    const { userDetails: { mode, userId, role, name, title, contactInfo, fieldError }, jobcard: { currentJobCardId } } = state;
    const { companyId } = getJobFromState( state, currentJobCardId );

    if ( !isEmpty( fieldError ) )
        return;

    const data = {
        name,
        title,
        role,
        contactInfo: JSON.parse( JSON.stringify( contactInfo ) )
    };

    // if this is a CC email user (no name), set the avatar colors
    if ( !name )
        data.preferences = { avatar: "black-white" };

    // we need to examine the changes to contactInfo (if any) to see if we've removed a record, which may necessitate
    // a change to the default, and to see if we've changed email address, which should be reflected in loginEmail.

    for ( let commsType of [ Coms.Types.EMAIL, Coms.Types.PHONE ]) {

        // Any edit has been applied to the default value; if the new values is blank, we'll remove that value in CW
        // so if there are other values, one of them should be assigned as the new default.
        const editedItem = coms.find.default( data.contactInfo, commsType );
        const editedItemIndex = coms.indexOf( data.contactInfo, editedItem );

        if ( editedItem && !editedItem?.value ) {

            // are there other items?
            if ( coms.filter( contactInfo, commsType ).length > 1 ) {

                // find the first non-default item
                const newDefault = data.contactInfo.find( item => item.type === commsType && !item.isDefault );

                // we have to change this to be the default item, so it takes the place of the blanked value.
                newDefault.isDefault = true;
            }

            // CW is going to delete the blank item so we'll do the same for consistency
            data.contactInfo.splice( editedItemIndex, 1 );
        }
    }

    // set the login email to the current default in case value or default has changed
    const newEmail = coms.find.best.email( data.contactInfo );
    data.loginEmail = newEmail?.value;

    if ( mode === userDialogMode.EDIT ) {
        data.userId = userId;
        dispatch( patchUserRequest( data ) );
    }
    else
        dispatch( createUserRequest({ data, companyId }) );

    dispatch( setCloseUserDialog() );
};


const addUserToJob = payload => async ( dispatch, getState ) => {

    // the user has been added to the db, now add to the current job
    const { jobcard: { currentJobCardId } } = getState();

    dispatch( setJobCardPatchUser({
        data:   {
            action:     ADD,
            people:     [ payload.userId ]
        },
        id:             currentJobCardId,
        fullRecord:     payload
    }) );
};

/** reducers */

const jobCardIdReducer = ( draft, payload ) => {
    draft.currentJobCardId = payload.jobId;

    saveToUserSession({ currentJobCardId: payload.jobId });

    // any change to job id resets these fields; they'll be reloaded for existing jobs
    draft.appointments = [];
    draft.appointmentHistory = [];
    draft._sortedAppointments = [];
    draft._usersOptions = [];
    draft._engineersOptions = [];
    draft.checklist = [];
    draft.notes = [];
    draft.messageText = '';
    draft.statusHidden = false;
    draft.draftMessageLoading = false;
    draft.assetsMap = {};
    draft.selectedAsset = {};
    draft.jobType = null;
    draft.jobSubType = null;
    draft.jobItem = null;

    // if we specify jobId and userId, set up the cache keys for draft notes
    if ( payload.jobId && payload.userId ) {

        // only clear this on open, not close; otherwise we lose the new appt info too soon in some cases
        draft.newAppointment = null;

        draft.messageDraftKey = `draftNote-${payload.userId}-${payload.jobId}-${NoteType.message}`;

        // now we have the cache keys, we can ask for the previous values
        getCacheData( draft.messageDraftKey );

        // inhibit updating the draft message until we get the existing version
        draft.draftMessageLoading = true;
    }
    else 
        draft.messageDraftKey = '';
    
};

// set one of the people lists in its entirety
const jobCardPeopleReducer = ( draft, people, listName ) => {
    draft[ listName ] = people;
    draft[ `_${listName}Options` ] = convertToLicoSelectOptions( draft[ listName ]);
};

// replace one of the people lists entirely with the supplied user
const jobCardSinglePersonReducer = ( draft, person ) => {

    // determine the list to replace from the person's role
    const listName = `${person.role}s`;
    const optionListName = `_${listName}Options`;

    // if we're changing client, record the old and new contact ids so we can update the job once
    // the client change has been saved.
    if ( person.role === UserRole.user ) {
        draft.deferredContactChange[ draft.currentJobCardId ] = {
            newContactId:   person.userId,
            oldContactIds:  draft.users.map( user => user.userId )
        };
    }

    draft[ listName ] = [ person ];
    draft[ optionListName ] = convertToLicoSelectOptions([ person ]);
};

// turn an appointment history record into a pseudo-appointment, ie something that can be treated like an appt,
// at least in the context of the JC appt list.
// use the historyId as the appointmentId so we have a unique key in the appointment list; we currently don't
// try go from this appointmentId back to the appointment.
const reviveAppointmentHistory = history => ({

    appointmentId:      history.historyId,
    startDate:          history.data.startDate,
    endDate:            history.data.endDate,
    userId:             history.data.userId || history.userId,
    state:              AppointmentState.rescheduled
});

const makeStaticAppointmentList = draft => {

    // combine the db appointments, any new appointment and the revived history items to get a complete list.
    // The filter is to remove a null new appt.
    draft._sortedAppointments = [ draft.appointments, draft.appointmentHistory.map( reviveAppointmentHistory ), draft.newAppointment ]
        .flat()
        .filter( Boolean )
        .sort( ( a, b ) => a.startDate.localeCompare( b.startDate ) );
};

const jobCardAppointmentReducer = ( draft, appointments ) => {
    draft.appointments = appointments;
    makeStaticAppointmentList( draft );
};

const jobCardAppointmentHistoryReducer = ( draft, appointmentHistory ) => {
    draft.appointmentHistory = appointmentHistory;
    makeStaticAppointmentList( draft );
};

const jobCardPersonReducer = ( draft, payload, listName ) => {
    const { fullRecord, data: { action, people } } = payload;

    // bail if the job card isn't open or if it's a busy card, which don't have people lists
    if ( !draft.currentJobCardId || !draft[ listName ])
        return;

    // the API supports adding or removing multiple users but the frontend doesn't right now,
    // so we're not handling adding multiple users since we also have to cope with adding the full record there.
    if ( action === ADD )
        draft[ listName ].push( fullRecord );
    else if ( action === DELETE )
        draft[ listName ] = draft[ listName ].filter( user => !people.includes( user.userId ) );
    else if ( action === 'update' ) {

        // initial use case is a contact changing on CW while the JC is open.
        // If the contact exists on the card, apply any changes.
        people.forEach( person => {
            const index = draft[ listName ].findIndex( user => user.userId === person.userId );
            if ( index >= 0 )
                Object.assign( draft[ listName ][ index ], person );

            // if name updated, check all notes for this user
            if ( person.name ) {
                draft.notes.forEach( note => {
                    if ( note.userId === person.userId )
                        note.name = person.name;
                });
            }
        });
    }
    else if ( action === 'clear ' )

        // used to clear the seeded default company user when the client id field is cleared
        draft[ listName ] = [];

    draft[ `_${listName}Options` ] = convertToLicoSelectOptions( draft[ listName ]);
};

const jobCardUpdateUserReducer = ( draft, payload ) => {

    // We can update or add users in the job card; this is the response from the server
    const index = draft.users.findIndex( user => user.userId === payload.userId );
    if ( index >= 0 )
        draft.users[ index ] = payload;
    else
        draft.users.push( payload );
    draft._usersOptions = convertToLicoSelectOptions( draft.users );
};

const jobCardChecklistItemUpdateReducer = ( draft, payload ) => {

    const index = draft.checklist.findIndex( item => item.checklistId === payload.checklistId );

    if ( index >= 0 )
        draft.checklist[ index ] = { ...draft.checklist[ index ], ...payload.task };
};

/**
 * Single note reducer
 * @param {object} draft - current state
 * @param {object} payload - newly added note plus user & billable info tunneled through from request
 * @returns new state
 */
const addJobNoteReducer = ( draft, payload ) => {

    // remove the temporary note if it matches the return; if the addition of a new note causes a send,
    // the pending display note will match the new one, not the old one
    if ( draft.pendingDisplayNote ) {
        if ( draft.pendingDisplayNote.noteId === payload.payload.noteId )
            draft.pendingDisplayNote = null;
    }

    // have to check the same job is still open; if not, do nothing, the note list
    // will be reloaded when the job card is reopened
    if ( draft.currentJobCardId === payload.payload.jobId ) {

        // check for the note's presence already before adding it; we may have added it already if
        // we had 2 notes sent in quick succession
        if ( draft.notes.map( n => n.noteId ).includes( payload.payload.noteId ) ) 
            return;

        // have to add the user info manually, this comes back with the note in the GET method
        draft.notes.push({
            ...payload.payload,
            avatar:         getAvatarFromUserOrCompany( payload.user ),
            name:           payload.user.name,
            role:           payload.user.role,
            billable:       payload.billable,
            billingTypeId:  payload.billingTypeId
        });

    }
};

/**
 * This completes the process for adding a timelog+note combination, where we
 * don't get the noteId back.
 * @param {object} draft - current state
 * @param {object} note - note details as sent to backend
 */
const timeLogNoteAddedReducer = ( draft, { note }) => {

    // this is all about clearing up the pending display note; if the PDN job doesn't match the
    // supplied note's job, we don't anything (since the notes for this job will be reloaded
    // when the job is next opened).

    if ( draft.pendingDisplayNote?.jobId === note.jobId ) {

        // if the job is open, copy the PDN details to the note list.
        if ( draft.currentJobCardId === note.jobId )
            draft.notes.push( draft.pendingDisplayNote );

        // remove the PDN
        draft.pendingDisplayNote = null;
    }
};

/**
 * Remove all items re the pending note and cancel the timeout
 * @param {object} draft - current state
 */
const clearPendingNoteReducer = draft => {
    clearTimeout( draft.undoNoteTimeout );
    draft.pendingNote = null;
    draft.pendingDisplayNote = null;
    draft.undoNoteTimeout = undefined;
};

/**
 * Handle changes to note fields.
 * 1. Update local field
 * 2. Update persistent cache with current WIP
 */
const setNoteTextReducer = ( draft, payload ) => {

    // local state
    draft.messageText = payload.text || '';
    draft.messageIsEmpty = !!payload.isEmpty;

    // update cache
    setCacheData( draft.messageDraftKey, { type: 'draftNote', noteType: payload.noteType, text: payload.text });
};

const noteDataDeletedReducer = ( draft, payload ) => {
    const index = draft.notes.findIndex( note => note.noteId === payload.noteId );
    if ( index >= 0 )
        draft.notes.splice( index, 1 );
};

const _setEditableTime = ( draft, editableTime ) => {
    draft.editableTime = editableTime;
    draft.validEditTime = editableTime.length === 0 || stringToNumMinutes( editableTime ) >= 0;
};

const _setTypeNames = ( draft, payload ) => {

    // use the standard reducer to store all formats of the name data
    setSomeTypeNames( integrationNames.JOB_STATUS, integrationNames.BILLING_TYPE )( draft, payload );

    // save some name ids to prevent frequent lookups.
    // We have to use the payload, since the state changes haven't been committed to the store yet.

    if ( payload.type === integrationNames.JOB_STATUS ) {

        // created-by-engineer is the default status, and the job record has a null statusId in this case.
        // find the id for this label.
        draft.defaultStatus = payload.payload.find( js => js.name === StatusNames.NOT_SCHEDULED ).licoriceNameId;

        // we often want to know if the job is completed
        draft.completedStatus = payload.payload.find( js => js.name === StatusNames.RESOLVED_JOB ).licoriceNameId;
    }

    if ( payload.type === integrationNames.BILLING_TYPE )
        draft.billingTypeId = payload.payload.find( bt => bt.name === BillingTypeNames.REGULAR ).licoriceNameId;

};

const _setDraftNoteText = ( draft, payload ) => {
    draft.messageText = payload.text;
    draft.draftMessageLoading = false;
};

const _setChatTabIndex = ( draft, tabIndex ) => {
    draft.chatTabIndex = tabIndex;
    saveToUserSession({ chatTabIndex: tabIndex });
};

const updateAssetsReducer = ( draft, payload ) => {
    const { assetId, assetName } = payload;
    if ( assetName )
        draft.assetsMap[ assetId ] = payload;

};
const updateAssetToJob = payload => async ( dispatch, getState ) => {
    const { jobcard: { currentJobCardId } } = getState();
    dispatch( addJobCardAsset({ jobId: currentJobCardId, assetId: payload.assetId }) );
    dispatch( updateJobCardAssets( payload ) );
};

const deleteAssetFromJob = payload => async ( dispatch, getState ) => {
    const { jobcard: { currentJobCardId } } = getState();
    dispatch( deleteJobCardAssetReq({ jobId: currentJobCardId, assetId: payload.assetId }) );
    dispatch( deleteJobCardAsset( payload ) );
};
const setJobCardAssetsReducer = ( draft, payload ) => {
    payload.forEach( asset => {
        draft.assetsMap[ asset.assetId ] = asset;
    });
};

const deleteJobCardAssetReducer = ( draft, payload ) => {
    // remove assetsMap with key assetId
    if ( payload.assetId )
        delete draft.assetsMap[ payload.assetId ];
};
const setSelectedAssetReducer = ( draft, payload ) => {
    draft.selectedAsset = payload;
    if ( !draft.selectedAsset.mode )
        setSelectedAssetMode( AssetDialogMode.VIEW );
};
const filterStringChanged = newFilterString => ( dispatch ) => {
    dispatch( setAssetFilterString( newFilterString ) );
};
const setFilterReducer = ( draft, payload ) => {
    draft.selectedAsset.filterString = payload;
};

const setSelectedAssetModeReducer = ( draft, payload ) => {
    draft.selectedAsset.mode = payload;
};

const _setJobNotes = ( draft, payload ) => {
    draft.notes = payload.map( item => ({ ...item, avatar: getAvatarFromUserOrCompany( item ) }) );

    // default billing type is the most recent one used, unless it's unmapped
    const { billingTypeId } = payload.length > 0 ? payload.at( -1 ) : {};
    if ( billingTypeId && draft.billingType.idToName[ billingTypeId ] !== BillingTypeNames.UNMAPPED )
        draft.billingTypeId = billingTypeId;
};

const _setPatchNoteList = ( draft, payload ) => {

    // Found Provider notes not stored in our db; if the job card isn't displayed, we don't care.
    if ( payload.jobId !== draft.currentJobCardId )
        return;

    draft.notes = [ ...draft.notes, ...payload.notes ];
};

const reducers = {
    [ setErrorJobCard ]:                ( draft, value ) => draft.error = value,
    [ setTouchedJobCard ]:              focusReducer,
    [ setMessageText ]:                 ( draft, text ) => draft.messageText = text,
    [ setNoteTextAction ]:              setNoteTextReducer,
    [ setDraftNoteText ]:               _setDraftNoteText,
    [ setCommentBillable ]:             ( draft, billable ) => draft.billable = billable,
    [ setBillingType ]:                 ( draft, billingTypeId ) => draft.billingTypeId = billingTypeId,
    [ setShowEditTimer ]:               ( draft, showEditTimer ) => draft.showEditTimer = showEditTimer,
    [ setEditableTime ]:                _setEditableTime,
    [ setCurrentJobCardId ]:            jobCardIdReducer,
    [ setJobCardDetails ]:              genericReducer,
    [ setJobCardAppointments ]:         jobCardAppointmentReducer,
    [ setJobCardUsers ]:                ( draft, payload ) => jobCardPeopleReducer( draft, payload, 'users' ),
    [ setJobCardEngineers ]:            ( draft, payload ) => jobCardPeopleReducer( draft, payload, 'engineers' ),
    [ setJobCardSinglePerson ]:         jobCardSinglePersonReducer,
    [ patchJobCardUsers ]:              ( draft, payload ) => jobCardPersonReducer( draft, payload, 'users' ),
    [ patchJobCardEngineers ]:          ( draft, payload ) => jobCardPersonReducer( draft, payload, 'engineers' ),
    [ setJobCardChecklist ]:            ( draft, payload ) => draft.checklist = payload,
    [ setJobCardChecklistItemDelete ]:  ( draft, payload ) => draft.checklist = draft.checklist.filter( item => item.checklistId !== payload.checklistId ),
    [ setJobCardChecklistItemUpdate ]:  jobCardChecklistItemUpdateReducer,
    [ setJobCardChecklistItemCreate ]:  ( draft, payload ) => draft.checklist.push( payload ),
    [ setAddJobNote ]:                  addJobNoteReducer,
    [ setJobNotes ]:                    _setJobNotes,
    [ setUndoNoteTimeout ]:             ( draft, timeout ) => draft.undoNoteTimeout = timeout,
    [ setPendingNote ]:                 ( draft, payload ) => draft.pendingNote = payload,
    [ setPendingDisplayNote ]:          ( draft, payload ) => draft.pendingDisplayNote = payload,
    [ clearPendingNote ]:               clearPendingNoteReducer,
    [ setUpdateJobcardUser ]:           jobCardUpdateUserReducer,
    [ noteDataDeleted ]:                noteDataDeletedReducer,
    [ setFinishTimeLog ]:               timeLogNoteAddedReducer,
    [ setAppointmentHistory ]:          jobCardAppointmentHistoryReducer,
    [ setJobCardNewAppointment ]:       ( draft, payload ) => { draft.newAppointment = payload; makeStaticAppointmentList( draft ); },
    [ setHideClientStatus ]:            ( draft, payload ) => draft.hideClientStatus = payload,
    [ clearContactChange ]:             ( draft, jobId ) => delete draft.deferredContactChange[ jobId ],
    [ setNotesFullView ]:               ( draft, payload ) => draft.notesFullView = payload,
    [ setChatTabIndex ]:                _setChatTabIndex,
    [ setJobCardAssets ]:               setJobCardAssetsReducer,
    [ deleteJobCardAsset ]:             deleteJobCardAssetReducer,
    [ updateJobCardAssets ]:            updateAssetsReducer,
    [ setSelectedAsset ]:               setSelectedAssetReducer,
    [ setAssetFilterString ]:           setFilterReducer,
    [ setSelectedAssetMode ]:           setSelectedAssetModeReducer,
    [ setTypeNames ]:                   _setTypeNames,
    [ setSkipClientEmail ]:             draft => draft.skipClientEmail = !draft.skipClientEmail,
    [ setPatchNoteList ]:               _setPatchNoteList,
    [ setBoardTypes ]:                  genericReducer,
    [ setJobTag ]:                      ( draft, payload ) => draft[ payload.tagType ] = payload.tagName,
    [ deleteJobTag ]:                   ( draft, payload ) => draft[ payload.tagType ] = null
};

export {
    setNoteText,
    queueNoteAction,
    sendPendingNote,
    setUndoPendingNote,
    refreshCurrentJobNotes,
    getJobCardPeopleIfOpen,
    setJobCardPatchUser,
    setJobCardPatchEngineer,
    addJobCardChecklistItem,
    deleteJobCardChecklistItem,
    updateJobCardChecklistItem,
    localJobCardChange,
    localJobCardSave,
    baseSlicePackage,
    setJobOwner,
    saveUserChanges,
    addJobCardAsset,
    updateAssetToJob,
    deleteAssetFromJob,
    filterStringChanged,
    getAssetTypes,
    billingTypeItemData
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
