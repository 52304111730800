/* eslint-disable */
/** ******************************************************************************************************************
 * Language translations for 'en'
 *
 * DO NOT MODIFY THIS FILE. IT IS AUTOMATICALLY GENERATED AND ANY CHANGES HERE WILL BE WIPED OUT WHEN
 * THE FILE IS REGENERATED.
 *********************************************************************************************************************/

const phrases = {
    " at ": " at ",
    " to see you on ": " to see you on ",
    " will see you on ": " will see you on ",
    "(Please enter a password which has a rating of Transcendent)": "(Please enter a password which has a rating of Transcendent)",
    "{assigner} assigned {linky} to you.": "{assigner} assigned {linky} to you.",
    "{linky} for {client} was reopened.": "{linky} for {client} was reopened.",
    "{linky} for {client} was resolved.": "{linky} for {client} was resolved.",
    "{linky} was assigned an unselected provider status '{statusName}'.": "{linky} was assigned an unselected provider status '{statusName}'.",
    "{name} added a note for {linky}.": "{name} added a note for {linky}.",
    "{name} changed status to {status} for {linky} for {clientName}": "{name} changed status to {status} for {linky} for {clientName}",
    "{name} created job {linky} for {companyName}": "{name} created job {linky} for {companyName}",
    "{name} was assigned to {linky}.": "{name} was assigned to {linky}.",
    "{name} was removed from {linky}.": "{name} was removed from {linky}.",
    "{provider} won't email updates until all Users have an email address. Add an email to the indicated Users(s).": "{provider} won't email updates until all Users have an email address. Add an email to the indicated Users(s).",
    "{userName} added an appointment for {linky}.": "{userName} added an appointment for {linky}.",
    "{userName} altered your appointment for {linky}.": "{userName} altered your appointment for {linky}.",
    "{userName} cancelled your appointment for {linky}.": "{userName} cancelled your appointment for {linky}.",
    "{userName} rejected job {linky}.": "{userName} rejected job {linky}.",
    "{userName} rescheduled your appointment for {linky}.": "{userName} rescheduled your appointment for {linky}.",
    "{value} minutes": "{value} minutes",
    "< Back": "< Back",
    "< Cancel": "< Cancel",
    "<no description for job>": "<no description for job>",
    "1 hour": "1 hour",
    "1Password": "1Password",
    "3 day": "3 day",
    "5 day": "5 day",
    "A cartoon image of a dashboard.": "A cartoon image of a dashboard.",
    "A cartoon image physical calendar, with an atlas in the background.": "A cartoon image physical calendar, with an atlas in the background.",
    "A job appointment request has been sent.": "A job appointment request has been sent.",
    "A password email has been sent to you.": "A password email has been sent to you.",
    "Accept": "Accept",
    "Activated": "Activated",
    "Active": "Active",
    "Add": "Add",
    "Add asset": "Add asset",
    "Add Client": "Add Client",
    "Add engineer": "Add engineer",
    "Add new Asset": "Add new Asset",
    "Add new item": "Add new item",
    "Add new user": "Add new user",
    "Add padding of": "Add padding of",
    "Add Site": "Add Site",
    "Add task": "Add task",
    "Add Team": "Add Team",
    "Add user": "Add user",
    "Add User": "Add User",
    "Admin": "Admin",
    "After Hours": "After Hours",
    "After scanning the barcode image, the app will display a six-digit code that you can enter below:": "After scanning the barcode image, the app will display a six-digit code that you can enter below:",
    "After-hours: Hourly rate outside business hours": "After-hours: Hourly rate outside business hours",
    "All Engineers are assigned to Teams": "All Engineers are assigned to Teams",
    "All other priorities will be mapped as Normal Priority": "All other priorities will be mapped as Normal Priority",
    "An error has occurred:": "An error has occurred:",
    "Appointment duration": "Appointment duration",
    "Appointments": "Appointments",
    "Are you sure you want to delete this {itemName}?": "Are you sure you want to delete this {itemName}?",
    "Are you sure you want to delete this {name}?": "Are you sure you want to delete this {name}?",
    "Assets": "Assets",
    "Authentication failed.": "Authentication failed.",
    "Authy": "Authy",
    "AVAILABLE ENGINEERS": "AVAILABLE ENGINEERS",
    "Back": "Back",
    "Billable": "Billable",
    "Billable: Hourly rate during business hours": "Billable: Hourly rate during business hours",
    "block quote": "block quote",
    "bold": "bold",
    "Book": "Book",
    "Booked": "Booked",
    "Build: ": "Build: ",
    "By checking each of the boxes below, you confirm that you have read, understood and agree to be bound by Licorice’s policies and licence terms. ": "By checking each of the boxes below, you confirm that you have read, understood and agree to be bound by Licorice’s policies and licence terms. ",
    "Calendar": "Calendar",
    "calendar days into the future": "calendar days into the future",
    "Can't cancel only appointment": "Can't cancel only appointment",
    "Cancel": "Cancel",
    "CC:": "CC:",
    "Changes saved.": "Changes saved.",
    "checking...": "checking...",
    "Checklist": "Checklist",
    "Clear All": "Clear All",
    "Click here to reload application": "Click here to reload application",
    "Click to undo sending the last message": "Click to undo sending the last message",
    "Client": "Client",
    "Client Appointments have been disabled": "Client Appointments have been disabled",
    "Client Appointments have been disabled.": "Client Appointments have been disabled.",
    "Client Chat": "Client Chat",
    "Client Name Already Exists": "Client Name Already Exists",
    "Client Name*": "Client Name*",
    "Client Scheduling": "Client Scheduling",
    "Client Scheduling Calendar": "Client Scheduling Calendar",
    "Client Scheduling Defaults": "Client Scheduling Defaults",
    "Client Scheduling Rules": "Client Scheduling Rules",
    "Clients": "Clients",
    "Clients can schedule up to": "Clients can schedule up to",
    "code block": "code block",
    "Commit: ": "Commit: ",
    "Company name": "Company name",
    "Company Name": "Company Name",
    "Completing integration": "Completing integration",
    "Configure Integration(s) to email Client Chat": "Configure Integration(s) to email Client Chat",
    "Confirm Password": "Confirm Password",
    "Congratulations!": "Congratulations!",
    "Congratulations! Sort of.": "Congratulations! Sort of.",
    "Connect": "Connect",
    "Connection could not be established. Please check the details and try again": "Connection could not be established. Please check the details and try again",
    "Continue >": "Continue >",
    "Copy to other engineer": "Copy to other engineer",
    "Could not connect to Licorice server.": "Could not connect to Licorice server.",
    "Country": "Country",
    "Create a password": "Create a password",
    "Create Account": "Create Account",
    "Create New Jobs as": "Create New Jobs as",
    "Create New Jobs In": "Create New Jobs In",
    "Create your account": "Create your account",
    "Creating DNS Worker...": "Creating DNS Worker...",
    "Creating instance {url}...": "Creating instance {url}...",
    "Critical priority message": "Critical priority message",
    "Customer Note:": "Customer Note:",
    "day": "day",
    "Default Appointment Duration": "Default Appointment Duration",
    "Default Engineer can't be deleted until the job is created": "Default Engineer can't be deleted until the job is created",
    "Default User": "Default User",
    "Delete": "Delete",
    "Delete appointment": "Delete appointment",
    "Delete this {itemName}?": "Delete this {itemName}?",
    "Delete this {name}?": "Delete this {name}?",
    "Description": "Description",
    "Details": "Details",
    "Disable Client Scheduling?": "Disable Client Scheduling?",
    "Disabling Client Scheduling will cause Licorice to stop replying to new client-generated tickets received via email, is this okay?": "Disabling Client Scheduling will cause Licorice to stop replying to new client-generated tickets received via email, is this okay?",
    "Discard": "Discard",
    "Display a Gravatar image for my avatar": "Display a Gravatar image for my avatar",
    "Do you choose to run Standalone and not integrate with a PSA?": "Do you choose to run Standalone and not integrate with a PSA?",
    "Done": "Done",
    "Done! Licorice is ready to use with {provider}.": "Done! Licorice is ready to use with {provider}.",
    "Download": "Download",
    "Download Journal Entries": "Download Journal Entries",
    "Download, print, or copy your recovery codes before continuing two-factor authentication setup below.": "Download, print, or copy your recovery codes before continuing two-factor authentication setup below.",
    "Eligible Engineers": "Eligible Engineers",
    "Email": "Email",
    "Email*": "Email*",
    "Enable": "Enable",
    "Enable Client Scheduling for these clients only:": "Enable Client Scheduling for these clients only:",
    "Enable Client Scheduling Interface": "Enable Client Scheduling Interface",
    "Enable Client Scheduling?": "Enable Client Scheduling?",
    "Enable two-factor authentication": "Enable two-factor authentication",
    "Enabling Client Scheduling will cause Licorice to reply to new client-generated tickets received via email, is this okay?": "Enabling Client Scheduling will cause Licorice to reply to new client-generated tickets received via email, is this okay?",
    "End User License Agreement": "End User License Agreement",
    "Engineer {name} has been added on {providerName}. {settings}": "Engineer {name} has been added on {providerName}. {settings}",
    "Engineer {name} has been deactivated on {providerName}. {settings}": "Engineer {name} has been deactivated on {providerName}. {settings}",
    "Engineer {name} has been reactivated on {providerName}. {settings}": "Engineer {name} has been reactivated on {providerName}. {settings}",
    "Engineer Chat": "Engineer Chat",
    "Engineer was added.": "Engineer was added.",
    "Engineers": "Engineers",
    "Enlarge ( ALT +F)": "Enlarge ( ALT +F)",
    "Enter filter...": "Enter filter...",
    "Enter license key:": "Enter license key:",
    "Enter the six-digit code from the application": "Enter the six-digit code from the application",
    "Environment: ": "Environment: ",
    "Error during integration": "Error during integration",
    "EULA": "EULA",
    "Event": "Event",
    "Filter": "Filter",
    "Filter Name": "Filter Name",
    "Find User or Client...": "Find User or Client...",
    "Finished": "Finished",
    "First-time login message": "First-time login message",
    "For step-by-step instructions see": "For step-by-step instructions see",
    "Forgot password?": "Forgot password?",
    "Full Name*": "Full Name*",
    "Go to Engineer settings": "Go to Engineer settings",
    "Go to settings": "Go to settings",
    "Google Authenticator": "Google Authenticator",
    "Grab Job (add/assign yourself as Owner).": "Grab Job (add/assign yourself as Owner).",
    "Gratis": "Gratis",
    "Gratis: Normally chargeable, but we're gifting it": "Gratis: Normally chargeable, but we're gifting it",
    "Historical sync is complete.": "Historical sync is complete.",
    "Historical sync is in progress.": "Historical sync is in progress.",
    "hour": "hour",
    "hours before a new appointment": "hours before a new appointment",
    "hr": "hr",
    "I have different work hours from the organisation": "I have different work hours from the organisation",
    "I have read and agree to Licorice’s ": "I have read and agree to Licorice’s ",
    "I've saved these somewhere safe": "I've saved these somewhere safe",
    "If enabled, Client Chat messages from the Integration platform will be emailed to Licorice users": "If enabled, Client Chat messages from the Integration platform will be emailed to Licorice users",
    "If this problem keeps occurring, please email us at {email}. If it's critical, please call your beta contact.": "If this problem keeps occurring, please email us at {email}. If it's critical, please call your beta contact.",
    "If you do not agree to these you must immediately cease using Licorice’s services:": "If you do not agree to these you must immediately cease using Licorice’s services:",
    "In addition to your username and password, you'll need to enter a code that Licorice sends to you via an app.": "In addition to your username and password, you'll need to enter a code that Licorice sends to you via an app.",
    "Inactive": "Inactive",
    "Incorrect username or password.": "Incorrect username or password.",
    "Inferior": "Inferior",
    "Instance {url} was not found; contact Licorice support": "Instance {url} was not found; contact Licorice support",
    "Instance created successfully": "Instance created successfully",
    "Integrating with {displayName}": "Integrating with {displayName}",
    "Integration": "Integration",
    "Integration complete": "Integration complete",
    "Integration enabled": "Integration enabled",
    "Integration failed. Please contact technical support.": "Integration failed. Please contact technical support.",
    "Integration in progress...": "Integration in progress...",
    "Integration with {provider} failed.": "Integration with {provider} failed.",
    "Integrations": "Integrations",
    "Internal": "Internal",
    "Internal Card": "Internal Card",
    "Invalid beta key": "Invalid beta key",
    "Invalid email.": "Invalid email.",
    "Invitation sent!": "Invitation sent!",
    "Invite": "Invite",
    "italic": "italic",
    "Item": "Item",
    "Job": "Job",
    "Job {title} was deleted by {deleterName}.": "Job {title} was deleted by {deleterName}.",
    "Job {title} was deleted on the provider.": "Job {title} was deleted on the provider.",
    "Job Deleted": "Job Deleted",
    "Job Id": "Job Id",
    "Job ID:": "Job ID:",
    "Jobs": "Jobs",
    "Join {sender} on Licorice": "Join {sender} on Licorice",
    "Journal": "Journal",
    "Keep me logged in  ": "Keep me logged in  ",
    "Keys": "Keys",
    "Lastpass": "Lastpass",
    "Licorice - The end of IT tickets forever": "Licorice - The end of IT tickets forever",
    "Licorice is currently in limited release while we monitor systems and performance.": "Licorice is currently in limited release while we monitor systems and performance.",
    "Licorice is the most advanced customer service interface in the world.": "Licorice is the most advanced customer service interface in the world.",
    "Licorice URL": "Licorice URL",
    "Linking users": "Linking users",
    "Loading...": "Loading...",
    "Location:": "Location:",
    "Login": "Login",
    "Logo URL": "Logo URL",
    "Logo URL not valid": "Logo URL not valid",
    "Logout": "Logout",
    "Lot/Apt/Unit/Suite": "Lot/Apt/Unit/Suite",
    "Main Phone": "Main Phone",
    "Map Internal Card Types": "Map Internal Card Types",
    "Map Job Statuses": "Map Job Statuses",
    "Map Personal Card Types": "Map Personal Card Types",
    "Map Priorities": "Map Priorities",
    "Mapping": "Mapping",
    "Maximum login attempts exceeded.": "Maximum login attempts exceeded.",
    "Me": "Me",
    "Meh": "Meh",
    "min": "min",
    "minutes after each appointments": "minutes after each appointments",
    "minutes before each appointment": "minutes before each appointment",
    "Mobile": "Mobile",
    "Move to other engineer": "Move to other engineer",
    "My work hours": "My work hours",
    "Name": "Name",
    "Name is mandatory": "Name is mandatory",
    "Name*": "Name*",
    "Network problem; contact Licorice support": "Network problem; contact Licorice support",
    "New Tab": "New Tab",
    "Newbie": "Newbie",
    "Next": "Next",
    "No": "No",
    "No Appointment Found": "No Appointment Found",
    "No Engineers are eligible for Client Appointments.": "No Engineers are eligible for Client Appointments.",
    "No Engineers are eligible for Client Appointments. Please contact Licorice Support.": "No Engineers are eligible for Client Appointments. Please contact Licorice Support.",
    "No entries found.": "No entries found.",
    "No information will be modified in {displayName} at this time.": "No information will be modified in {displayName} at this time.",
    "No notes": "No notes",
    "No valid Job Statuses": "No valid Job Statuses",
    "No valid Service Boards": "No valid Service Boards",
    "None": "None",
    "Not a valid email address": "Not a valid email address",
    "Not Billable": "Not Billable",
    "Not-billable: There is a reason this is not chargeable": "Not-billable: There is a reason this is not chargeable",
    "Notes disabled": "Notes disabled",
    "of": "of",
    "Okay": "Okay",
    "Open Hours": "Open Hours",
    "Open the underlying ticket in a new tab": "Open the underlying ticket in a new tab",
    "Organisation": "Organisation",
    "Package: ": "Package: ",
    "Page": "Page",
    "Password": "Password",
    "Password changed successfully": "Password changed successfully",
    "Password must be at least 12 characters.": "Password must be at least 12 characters.",
    "Password rating must be Transcendent": "Password rating must be Transcendent",
    "Password Reset": "Password Reset",
    "Password strength:": "Password strength:",
    "Patent pending. ©{currentYear} Licorice.": "Patent pending. ©{currentYear} Licorice.",
    "People celebrating": "People celebrating",
    "Personal": "Personal",
    "Personal Card": "Personal Card",
    "Phone": "Phone",
    "Please enter a valid email address": "Please enter a valid email address",
    "Please enter the verification code from your 2FA app below to login:": "Please enter the verification code from your 2FA app below to login:",
    "Please fill in all required fields": "Please fill in all required fields",
    "Please wait while Licorice syncs data from {displayName}.": "Please wait while Licorice syncs data from {displayName}.",
    "Position": "Position",
    "Post Code": "Post Code",
    "Press Book to book the appointment": "Press Book to book the appointment",
    "Press Ctrl-Enter to add message": "Press Ctrl-Enter to add message",
    "Press Download to save all journal entries matching the current filter.": "Press Download to save all journal entries matching the current filter.",
    "Privacy": "Privacy",
    "Privacy Policy": "Privacy Policy",
    "Profile picture": "Profile picture",
    "Provider": "Provider",
    "Provider error: {message}": "Provider error: {message}",
    "Provider not found; check the API URL": "Provider not found; check the API URL",
    "Provider status for '{statusName}' for {linky} not on service board.": "Provider status for '{statusName}' for {linky} not on service board.",
    "Public/Private keys not valid": "Public/Private keys not valid",
    "QR Code to scan": "QR Code to scan",
    "Recovery codes are used to access your account in the event you cannot receive two-factor authentication codes.": "Recovery codes are used to access your account in the event you cannot receive two-factor authentication codes.",
    "Refresh": "Refresh",
    "Remove": "Remove",
    "Remove or reassign all appointments for this Engineer before deleting them": "Remove or reassign all appointments for this Engineer before deleting them",
    "Request Appointment": "Request Appointment",
    "Require 2-factor authentication for engineers": "Require 2-factor authentication for engineers",
    "Require a minimum of": "Require a minimum of",
    "Resend": "Resend",
    "Reset Password": "Reset Password",
    "Resize ( ALT +F)": "Resize ( ALT +F)",
    "Resolved": "Resolved",
    "Run Standalone?": "Run Standalone?",
    "s": "s",
    "Safely guessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)": "Safely guessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)",
    "Save": "Save",
    "Scan this barcode with your app": "Scan this barcode with your app",
    "Scan this image with the two-factor authentication app. If you can't use a barcode, enter this code manually instead:": "Scan this image with the two-factor authentication app. If you can't use a barcode, enter this code manually instead:",
    "Search": "Search",
    "Search for teammates": "Search for teammates",
    "Security": "Security",
    "Select a new Job Owner to delete this contact": "Select a new Job Owner to delete this contact",
    "Select a new Job Owner to delete this Engineer": "Select a new Job Owner to delete this Engineer",
    "Select an appointment time to see available Engineers": "Select an appointment time to see available Engineers",
    "Send reset link": "Send reset link",
    "Send this appointment to your calendar at ": "Send this appointment to your calendar at ",
    "Set as Owner": "Set as Owner",
    "Set Resolved Jobs as": "Set Resolved Jobs as",
    "Set up using an app": "Set up using an app",
    "Settings": "Settings",
    "Sign in": "Sign in",
    "Sign up": "Sign up",
    "Sign Up": "Sign Up",
    "Since this is your first time logging in, you must accept Licorice's policies and license terms to continue.": "Since this is your first time logging in, you must accept Licorice's policies and license terms to continue.",
    "Singular": "Singular",
    "Site Name Already Exists": "Site Name Already Exists",
    "Site Name*": "Site Name*",
    "Sites": "Sites",
    "Some Time Today": "Some Time Today",
    "Somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)": "Somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)",
    "Start": "Start",
    "Start Time must come before End Time": "Start Time must come before End Time",
    "Starting": "Starting",
    "State": "State",
    "Status": "Status",
    "Status Note": "Status Note",
    "Step": "Step",
    "Street Address": "Street Address",
    "strikethrough": "strikethrough",
    "SubType": "SubType",
    "Sync": "Sync",
    "Syncing asset statuses": "Syncing asset statuses",
    "Syncing asset types": "Syncing asset types",
    "Syncing assets": "Syncing assets",
    "Syncing companies": "Syncing companies",
    "Syncing company IDs": "Syncing company IDs",
    "Syncing contacts": "Syncing contacts",
    "Syncing engineers": "Syncing engineers",
    "Syncing manufacturers": "Syncing manufacturers",
    "Syncing site counts": "Syncing site counts",
    "Syncing sites": "Syncing sites",
    "Syncing SLAs": "Syncing SLAs",
    "Syncing tickets": "Syncing tickets",
    "Syncing user information": "Syncing user information",
    "Team Name": "Team Name",
    "Teams": "Teams",
    "Technical": "Technical",
    "Terms": "Terms",
    "Terms & Conditions": "Terms & Conditions",
    "Thank you, you have made an appointment and ": "Thank you, you have made an appointment and ",
    "The client cannot be changed after chat entries or assets are added, or the job is resolved.": "The client cannot be changed after chat entries or assets are added, or the job is resolved.",
    "The current Location and Department settings do not match any Service Boards": "The current Location and Department settings do not match any Service Boards",
    "The error has been recorded by the system. Please use the tracking number shown above if you wish to make an enquiry.": "The error has been recorded by the system. Please use the tracking number shown above if you wish to make an enquiry.",
    "The image referenced by this URL will be used as the organisation logo.": "The image referenced by this URL will be used as the organisation logo.",
    "The Job cannot be removed until it has been fully added": "The Job cannot be removed until it has been fully added",
    "The Job cannot be removed until the Note has been added": "The Job cannot be removed until the Note has been added",
    "The timer cannot be started because the company is currently read-only": "The timer cannot be started because the company is currently read-only",
    "There are no options for this list": "There are no options for this list",
    "There are overlapping items": "There are overlapping items",
    "There is an existing Team with this name": "There is an existing Team with this name",
    "There was a problem setting up your site.": "There was a problem setting up your site.",
    "There was an error sending the reset password email": "There was an error sending the reset password email",
    "These Engineers are not assigned to a Team:": "These Engineers are not assigned to a Team:",
    "These Engineers were assigned to {linky}: {names}.": "These Engineers were assigned to {linky}: {names}.",
    "These Engineers were removed from {linky}: {names}.": "These Engineers were removed from {linky}: {names}.",
    "This appointment has already been scheduled": "This appointment has already been scheduled",
    "This code has been cancelled": "This code has been cancelled",
    "This code has been replaced by a more recent one": "This code has been replaced by a more recent one",
    "This code has been used already": "This code has been used already",
    "This code has expired": "This code has expired",
    "This code is for an inactive user": "This code is for an inactive user",
    "This enables selective use of the Client Scheduling feature (e.g. for testing)": "This enables selective use of the Client Scheduling feature (e.g. for testing)",
    "This field is required.": "This field is required.",
    "This invitation has already been accepted. You can login {here}": "This invitation has already been accepted. You can login {here}",
    "This is how your name will appear to others": "This is how your name will appear to others",
    "This is not a valid Licorice invitation.": "This is not a valid Licorice invitation.",
    "This is not a valid Licorice reset code": "This is not a valid Licorice reset code",
    "This is the master switch for Client Scheduling": "This is the master switch for Client Scheduling",
    "This Job has been deleted": "This Job has been deleted",
    "This job has been resolved and cannot be deleted": "This job has been resolved and cannot be deleted",
    "This job has saved worklog entries and cannot be deleted": "This job has saved worklog entries and cannot be deleted",
    "This link has expired": "This link has expired",
    "This link is invalid": "This link is invalid",
    "This note will be emailed to the client as usual": "This note will be emailed to the client as usual",
    "This note will not be emailed to the client": "This note will not be emailed to the client",
    "This token is not an appointment token": "This token is not an appointment token",
    "This will be the account you use to access your Licorice. If there is an existing instance for your company, please ask an existing user to invite you.": "This will be the account you use to access your Licorice. If there is an existing instance for your company, please ask an existing user to invite you.",
    "Ticket Billing": "Ticket Billing",
    "Ticket Work Types": "Ticket Work Types",
    "Tickets": "Tickets",
    "Time entered today": "Time entered today",
    "Time Entry": "Time Entry",
    "Time entry rounding": "Time entry rounding",
    "Times cannot be entered since the company is currently read-only": "Times cannot be entered since the company is currently read-only",
    "Title": "Title",
    "to": "to",
    "To enable two-factor authentication please confirm your current password.": "To enable two-factor authentication please confirm your current password.",
    "To request access to Licorice please visit:": "To request access to Licorice please visit:",
    "To reset your password, enter the email you use to sign in to {domain}.": "To reset your password, enter the email you use to sign in to {domain}.",
    "To unpause this Job you need a free slot on the Pegboard.": "To unpause this Job you need a free slot on the Pegboard.",
    "Today": "Today",
    "Tomorrow": "Tomorrow",
    "Too guessable: risky password. (guesses < 10^3)": "Too guessable: risky password. (guesses < 10^3)",
    "Total estimated time:": "Total estimated time:",
    "Total:": "Total:",
    "Tracking number:": "Tracking number:",
    "Transcendent": "Transcendent",
    "Two-factor authentication adds an additional layer of security to your account.": "Two-factor authentication adds an additional layer of security to your account.",
    "Two-factor authentication is required by your organisation": "Two-factor authentication is required by your organisation",
    "Type": "Type",
    "Type a detailed description": "Type a detailed description",
    "Type a short title": "Type a short title",
    "UI Environment: ": "UI Environment: ",
    "underline": "underline",
    "Undo": "Undo",
    "Unexpected error.": "Unexpected error.",
    "Unknown": "Unknown",
    "Unknown problem with token": "Unknown problem with token",
    "Unmapped": "Unmapped",
    "Unsaved Changes": "Unsaved Changes",
    "Use this alternative email for my Gravatar image": "Use this alternative email for my Gravatar image",
    "User Email Already Exists": "User Email Already Exists",
    "User not authenticated; check the Company Name": "User not authenticated; check the Company Name",
    "Users": "Users",
    "Valid Job Statuses": "Valid Job Statuses",
    "Valid Service Boards": "Valid Service Boards",
    "Version: ": "Version: ",
    "Very guessable: protection from throttled online attacks. (guesses < 10^6)": "Very guessable: protection from throttled online attacks. (guesses < 10^6)",
    "Very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)": "Very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)",
    "Warning: Status does not exist for Default Board": "Warning: Status does not exist for Default Board",
    "We recommend using {authy}, {googleAuth}, {onePassword}, or {lastpass}.": "We recommend using {authy}, {googleAuth}, {onePassword}, or {lastpass}.",
    "Week": "Week",
    "Welcome to Licorice": "Welcome to Licorice",
    "When a user attempts to schedule an appointment as Critical priority, this message will be displayed": "When a user attempts to schedule an appointment as Critical priority, this message will be displayed",
    "When a user creates a new ticket, email them to book an appointment": "When a user creates a new ticket, email them to book an appointment",
    "When cleared, Licorice clears all login data on tab refresh/close. Be careful using this when logging on from a client's PC.": "When cleared, Licorice clears all login data on tab refresh/close. Be careful using this when logging on from a client's PC.",
    "Work days": "Work days",
    "Work email": "Work email",
    "Yes": "Yes",
    "Yes, Standalone": "Yes, Standalone",
    "Yesterday": "Yesterday",
    "You can't delete the only User for a Job ": "You can't delete the only User for a Job ",
    "You have cancelled the scheduling of this appointment. You can restart scheduling by clicking on the link in the email you received.": "You have cancelled the scheduling of this appointment. You can restart scheduling by clicking on the link in the email you received.",
    "You have selected an appointment with ": "You have selected an appointment with ",
    "You have unsaved changes. Would you like to save or discard them? (Or cancel the navigation.)": "You have unsaved changes. Would you like to save or discard them? (Or cancel the navigation.)",
    "You must accept all terms to continue.": "You must accept all terms to continue.",
    "You must provide a password": "You must provide a password",
    "You must select at least one day in each item": "You must select at least one day in each item",
    "You need to schedule an Appointment before you can remove this Job from the Pegboard": "You need to schedule an Appointment before you can remove this Job from the Pegboard",
    "You've been logged out due to being made inactive.": "You've been logged out due to being made inactive.",
    "You've been logged out due to gaining Admin rights.": "You've been logged out due to gaining Admin rights.",
    "You've been logged out due to losing Admin rights.": "You've been logged out due to losing Admin rights.",
    "You've completed the setup process! Now we have to set up your new site.": "You've completed the setup process! Now we have to set up your new site.",
    "Your appointment for {linky} at {startTimeStr} on {startDateStr} was deleted on the provider.": "Your appointment for {linky} at {startTimeStr} on {startDateStr} was deleted on the provider.",
    "Your company is the space where you and your team can collaborate with customers.": "Your company is the space where you and your team can collaborate with customers.",
    "Your Licorice instance is ready to go! You're a few steps away from the end of IT tickets forever.": "Your Licorice instance is ready to go! You're a few steps away from the end of IT tickets forever.",
    "Your Licorice URL": "Your Licorice URL",
    "Your organisation requires two-factor authentication on all accounts, please follow the prompts to set up 2FA now.": "Your organisation requires two-factor authentication on all accounts, please follow the prompts to set up 2FA now.",
    "Your Profile": "Your Profile",
    "yr": "yr"
};

export { phrases as default };
